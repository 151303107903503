// gradient
$gradient-5: linear-gradient(274.05deg, #CDACFC -8.09%, #8B3FFA 61.08%);

// white
$white: #FFFFFF;
$black: #111111;

// Fills and Strokes
$fill-stroke-50: #D7DEE5;
$fill-stroke-100: #A0A9BA;
$fill-stroke-200: #404150;
$fill-stroke-300: #2C3236;
$fill-stroke-400: #1C1F24;
$fill-stroke-500: #111111;

// Gray
$gray-50: #F9F9F9;
$gray-100: #F5F5F5;
$gray-200: #F1F1F1;
$gray-300: #E0E0E0;
$gray-400: #D6D6D6;
$gray-500: #CCCCCC;

// Dark Black
$dark-black-100: #B8B8BB;
$dark-black-200: #78787D;
$dark-black-300: #1D1934;
$dark-black-400: #141124;
$dark-black-500: #000000;

// Electric Blue
$electric-blue-100: #B8B4EC;
$electric-blue-200: #8177E1;
$electric-blue-300: #3900D9;
$electric-blue-400: #2B00A5;
$electric-blue-500: #1F0076;

// Electric Red
$electric-red-100: #FDE9EB;
$electric-red-200: #FA9EA7;
$electric-red-300: #F83B5A;
$electric-red-400: #C02D45;
$electric-red-500: #831F2F;

// Blue
$blue-100: #E7F5FE;
$blue-200: #A3DEFC;
$blue-300: #26C5FB;
$blue-400: #1C93BB;
$blue-500: #105870;

// Orange
$orange-100: #FFEFE4;
$orange-200: #FFCB99;
$orange-300: #FFA700;
$orange-400: #BD7B00;
$orange-500: #724A00;

// Electric Green
$electric-green-100: #EDFAE4;
$electric-green-200: #C4F0A1;
$electric-green-300: #99E83A;
$electric-green-400: #78B62D;
$electric-green-500: #527C1F;

// Magenta
$magenta-100: #F5C9EA;
$magenta-200: #E878CB;
$magenta-300: #DF43B7;
$magenta-400: #9F2F82;
$magenta-500: #7F2668;

// Violet
$violet-100: #E6D5FD;
$violet-200: #CDACFC;
$violet-300: #9F6FFB;
$violet-400: #8B3FFA;
$violet-500: #482F6B;

// Error
$error-100: #FCDBDC;
$error-200: #FAAAAD;
$error-300: #F74F58;
$error-400: #C73F46;
$error-500: #8D2D32;

// Warning
$warning-100: #FDF1CB;
$warning-200: #FCE694;
$warning-300: #FCDC43;
$warning-400: #D8BD39;
$warning-500: #B39D2F;

// Success
$success-100: #E1FFEC;
$success-200: #AEF9CD;
$success-300: #01ED8C;
$success-400: #00C675;
$success-500: #009256;

// marcas
$uerre-black: #231F20;
$uerre-purple: #39277A;
$uerre-blue-c3: #004F7D;
$uerre-red-c80: #FC6370;

$unid-purple: #121134;
$unid-yellow: #F1BA28;
$unid-yellow-main: #F6BE00;
$unid-blue: #1E1A34;
$unid-magenta: #D50057;
$unid-business-blue: #3700D1;
$unid-business-magenta: #D30DFF;
$unid-business-orange: #FF7E00;

$hh-black: #231F20;
$hh-yellow: #FFFF00;

$ld-blue: #0071CE;

$advenio-red-main: #ED1A3B;

$iesalud-green-m80: #2DBFBA;
$iesalud-yellow: #DEDC00;


// the :export directive is the magic sauce for webpack
:export {
  white: $white;
  black: $black;
  // Fills and Strokes
  fillStroke50: $fill-stroke-50;
  fillStroke100: $fill-stroke-100;
  fillStroke200: $fill-stroke-200;
  fillStroke300: $fill-stroke-300;
  fillStroke400: $fill-stroke-400;
  fillStroke500: $fill-stroke-500;
  // Gray
  gray50: $gray-50;
  gray100: $gray-100;
  gray200: $gray-200;
  gray300: $gray-300;
  gray400: $gray-400;
  gray500: $gray-500;
  // Dark Black
  darkBlack100: $dark-black-100;
  darkBlack200: $dark-black-200;
  darkBlack300: $dark-black-300;
  darkBlack400: $dark-black-400;
  darkBlack500: $dark-black-500;
  // Electric Blue
  electricBlue100: $electric-blue-100;
  electricBlue200: $electric-blue-200;
  electricBlue300: $electric-blue-300;
  electricBlue400: $electric-blue-400;
  electricBlue500: $electric-blue-500;
  // Electric Red
  electricRed100: $electric-red-100;
  electricRed200: $electric-red-200;
  electricRed300: $electric-red-300;
  electricRed400: $electric-red-400;
  electricRed500: $electric-red-500;
  // Blue
  blue100: $blue-100;
  blue200: $blue-200;
  blue300: $blue-300;
  blue400: $blue-400;
  blue500: $blue-500;
  // Orange
  orange100: $orange-100;
  orange200: $orange-200;
  orange300: $orange-300;
  orange400: $orange-400;
  orange500: $orange-500;
  // Electric Green
  electricGreen100: $electric-green-100;
  electricGreen200: $electric-green-200;
  electricGreen300: $electric-green-300;
  electricGreen400: $electric-green-400;
  electricGreen500: $electric-green-500;
  // Magenta
  magenta100: $magenta-100;
  magenta200: $magenta-200;
  magenta300: $magenta-300;
  magenta400: $magenta-400;
  magenta500: $magenta-500;
  // Violet
  violet100: $violet-100;
  violet200: $violet-200;
  violet300: $violet-300;
  violet400: $violet-400;
  violet500: $violet-500;
  // Error
  error100: $error-100;
  error200: $error-200;
  error300: $error-300;
  error400: $error-400;
  error500: $error-500;
  // Warning
  warning100: $warning-100;
  warning200: $warning-200;
  warning300: $warning-300;
  warning400: $warning-400;
  warning500: $warning-500;
  // Success
  success100: $success-100;
  success200: $success-200;
  success300: $success-300;
  success400: $success-400;
  success500: $success-500;
  // marcas
  uerreBlack: $uerre-black;
  uerrePurple: $uerre-purple;
  uerreBlueC3: $uerre-blue-c3;
  unidPurple: $unid-purple;
  unidYellow: $unid-yellow;
  unidBlue: $unid-blue;
  unidMagenta: $unid-magenta;
  unidBusinessBlue: $unid-business-blue;
  unidBusinessMagenta: $unid-business-magenta;
  unidBusinessOrange: $unid-business-orange;
  hhBlack: $hh-black;
  hhYellow: $hh-yellow;
  ldBlue: $ld-blue;
  advenioRedMain: $advenio-red-main;
  iesaludGreenM80: $iesalud-green-m80;
  uerreRedC80: $uerre-red-c80;
  unidYellowMain: $unid-yellow-main;
  iesaludYellow: $iesalud-yellow;

  // gradient
  gradient5: $gradient-5;
}