@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins&display=swap');

@import "./variables/colors.scss";
@import "./variables/breakpoints.scss";
@import "./scss/buttons.scss";
@import "./scss/chips.scss";
@import "./utilities/typography.scss";
@import "./utilities/spacing.scss";
@import "./utilities/icon.scss";
@import "./utilities/display.scss";
@import "./utilities/flex-layout.scss";

input, button, textarea{
    font-family: 'Inter', sans-serif !important;
}

@mixin offset-margin($margin, $value){
    margin-#{$margin}: $value !important;
}
@mixin padding($side, $value){
    margin-#{$side}: $value !important;
}
  
@for $i from 1 through 60 {
    @each $abbr, $name in ("t": "top", "r": "right", "b": "bottom", "l": "left"){

        .offset-margin-#{$name}-#{$i}{//offset-margin-bottom-1
            @include offset-margin($name, 1px * $i) 
        }

        .offset-m#{$abbr}-#{$i}{//offset-mt-1
            @include offset-margin($name, 4px * $i) 
        } 

        .padding-#{$i}{//padding-1
            padding: (8px * $i) !important;
            // @include padding($name, 4px * $i) 
        } 
        .margin-#{$abbr}-#{$i}{//margin-t-1
            @include offset-margin($name, 8px * $i)  
        } 
    }
}

// Gradients
.gradient-{
    &blue-{
        &1{
            background: linear-gradient(113.16deg, #3900D9 4.18%, #1F0076 82.04%);
        }
    }
    &electric-{
        &blue{
            background: linear-gradient(196.04deg, #3900D9 22.98%, #1F0076 89.71%);;
        }
    }
    &violet{
        background: linear-gradient(116.65deg, #200177 20.91%, #9F6FFB 77.57%);
    }
}

// Border
.border-{
    &radius-{
        &8{
            border-radius: 8px;
        }
    }
    &slim{
        border-bottom-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        // border-width: .5px !important;
    }
    &color-{
        &fill-stroke-{
            &100{
                border-color: $fill-stroke-100 !important;
            }
            &200{
                border-color: $fill-stroke-200 !important;
            }
            &300{
                border-color: $fill-stroke-300 !important;
            }
            &400{
                border-color: $fill-stroke-400 !important;
            }
            &500{
                border-color: $fill-stroke-500 !important;
            }
        }
    }
}

.PointCard-outlined-blue{
    background: rgba(46, 207, 232, 0.16) !important;
    border: 0.5px solid #2ECFE8 !important;
}

.PointCard-outlined-violet-300{
    background: rgba(159, 111, 251, 0.16) !important;
    border: 0.5px solid $violet-300 !important;
}

.PointCard-outlined-fillStroke-400{
    background: $fill-stroke-400 !important;
    border: 0.5px solid $fill-stroke-200 !important;
}


.caption-small, .overline-small{
    font-size: 10px !important;
}
.overline-xs{
    font-size: 8px !important;
}

.semi-bold{
    font-weight: 600 !important;
}
.bold{
    font-weight: 700 !important;
}

// Background
.bg-{
    &white{
        background: $white !important;
    }
    &transparent{
        background: transparent !important;
    }
    &fill-stroke-{
        &50{
            background: $fill-stroke-50 !important;
        }
        &100{
            background: $fill-stroke-100 !important;
        }
        &200{
            background: $fill-stroke-200 !important;
        }
        &300{
            background: $fill-stroke-300 !important;
        }
        &400{
            background: $fill-stroke-400 !important;
        }
        &500{
            background: $fill-stroke-500 !important;
        }
    }
    &gray-{
        &100{
            background: $gray-100 !important;
        }
        &200{
            background: $gray-200 !important;
        }
        &300{
            background: $gray-300 !important;
        }
        &400{
            background: $gray-400 !important;
        }
        &500{
            background: $gray-500 !important;
        }
    }
    &dark-black-{
        &100{
            background: $dark-black-100 !important;
        }
        &200{
            background: $dark-black-200 !important;
        }
        &300{
            background: $dark-black-300 !important;
        }
        &400{
            background: $dark-black-400 !important;
        }
        &500{
            background: $dark-black-500 !important;
        }
    }
    &electric-{
        &blue-{
            &100{
                background: $electric-blue-100 !important;
            }
            &200{
                background: $electric-blue-200 !important;
            }
            &300{
                background: $electric-blue-300 !important;
            }
            &400{
                background: $electric-blue-400 !important;
            }
            &500{
                background: $electric-blue-500 !important;
            }
        }
        &red-{
            &100{
                background: $electric-red-100 !important;
            }
            &200{
                background: $electric-red-200 !important;
            }
            &300{
                background: $electric-red-300 !important;
            }
            &400{
                background: $electric-red-400 !important;
            }
            &500{
                background: $electric-red-500 !important;
            }
        }
        &green-{
            &100{
                background: $electric-green-100 !important;
            }
            &200{
                background: $electric-green-200 !important;
            }
            &300{
                background: $electric-green-300 !important;
            }
            &400{
                background: $electric-green-400 !important;
            }
            &500{
                background: $electric-green-500 !important;
            }
        }
    }
    &blue-{
        &100{
            background: $blue-100 !important;
        }
        &200{
            background: $blue-200 !important;
        }
        &300{
            background: $blue-300 !important;
        }
        &400{
            background: $blue-400 !important;
        }
        &500{
            background: $blue-500 !important;
        }
    }
    &orange-{
        &100{
            background: $orange-100 !important;
        }
        &200{
            background: $orange-200 !important;
        }
        &300{
            background: $orange-300 !important;
        }
        &400{
            background: $orange-400 !important;
        }
        &500{
            background: $orange-500 !important;
        }
    }
    &magenta-{
        &100{
            background: $magenta-100 !important;
        }
        &200{
            background: $magenta-200 !important;
        }
        &300{
            background: $magenta-300 !important;
        }
        &400{
            background: $magenta-400 !important;
        }
        &500{
            background: $magenta-500 !important;
        }
    }
    &violet-{
        &100{
            background: $violet-100 !important;
        }
        &200{
            background: $violet-200 !important;
        }
        &300{
            background: $violet-300 !important;
        }
        &400{
            background: $violet-400 !important;
        }
        &500{
            background: $violet-500 !important;
        }
    }
    &error-{
        &100{
            background: $error-100 !important;
        }
        &200{
            background: $error-200 !important;
        }
        &300{
            background: $error-300 !important;
        }
        &400{
            background: $error-400 !important;
        }
        &500{
            background: $error-500 !important;
        }
    }
    &warning-{
        &100{
            background: $warning-100 !important;
        }
        &200{
            background: $warning-200 !important;
        }
        &300{
            background: $warning-300 !important;
        }
        &400{
            background: $warning-400 !important;
        }
        &500{
            background: $warning-500 !important;
        }
    }
    &success-{
        &100{
            background: $success-100 !important;
        }
        &200{
            background: $success-200 !important;
        }
        &300{
            background: $success-300 !important;
        }
        &400{
            background: $success-400 !important;
        }
        &500{
            background: $success-500 !important;
        }
    }
}

.text-{
    &white{
        color: $white !important;
    }
    &fill-stroke-{
        &100{
            color: $fill-stroke-100 !important;
        }
        &200{
            color: $fill-stroke-200 !important;
        }
        &300{
            color: $fill-stroke-300 !important;
        }
        &400{
            color: $fill-stroke-400 !important;
        }
        &500{
            color: $fill-stroke-500 !important;
        }
    }
    &gray-{
        &100{
            color: $gray-100 !important;
        }
        &200{
            color: $gray-200 !important;
        }
        &300{
            color: $gray-300 !important;
        }
        &400{
            color: $gray-400 !important;
        }
        &500{
            color: $gray-500 !important;
        }
    }
    &dark-black-{
        &100{
            color: $dark-black-100 !important;
        }
        &200{
            color: $dark-black-200 !important;
        }
        &300{
            color: $dark-black-300 !important;
        }
        &400{
            color: $dark-black-400 !important;
        }
        &500{
            color: $dark-black-500 !important;
        }
    }
    &electric-{
        &blue-{
            &100{
                color: $electric-blue-100 !important;
            }
            &200{
                color: $electric-blue-200 !important;
            }
            &300{
                color: $electric-blue-300 !important;
            }
            &400{
                color: $electric-blue-400 !important;
            }
            &500{
                color: $electric-blue-500 !important;
            }
        }
        &red-{
            &100{
                color: $electric-red-100 !important;
            }
            &200{
                color: $electric-red-200 !important;
            }
            &300{
                color: $electric-red-300 !important;
            }
            &400{
                color: $electric-red-400 !important;
            }
            &500{
                color: $electric-red-500 !important;
            }
        }
        &green-{
            &100{
                color: $electric-green-100 !important;
            }
            &200{
                color: $electric-green-200 !important;
            }
            &300{
                color: $electric-green-300 !important;
            }
            &400{
                color: $electric-green-400 !important;
            }
            &500{
                color: $electric-green-500 !important;
            }
        }
    }
    &blue-{
        &100{
            color: $blue-100 !important;
        }
        &200{
            color: $blue-200 !important;
        }
        &300{
            color: $blue-300 !important;
        }
        &400{
            color: $blue-400 !important;
        }
        &500{
            color: $blue-500 !important;
        }
    }
    &orange-{
        &100{
            color: $orange-100 !important;
        }
        &200{
            color: $orange-200 !important;
        }
        &300{
            color: $orange-300 !important;
        }
        &400{
            color: $orange-400 !important;
        }
        &500{
            color: $orange-500 !important;
        }
    }
    &magenta-{
        &100{
            color: $magenta-100 !important;
        }
        &200{
            color: $magenta-200 !important;
        }
        &300{
            color: $magenta-300 !important;
        }
        &400{
            color: $magenta-400 !important;
        }
        &500{
            color: $magenta-500 !important;
        }
    }
    &violet-{
        &100{
            color: $violet-100 !important;
        }
        &200{
            color: $violet-200 !important;
        }
        &300{
            color: $violet-300 !important;
        }
        &400{
            color: $violet-400 !important;
        }
        &500{
            color: $violet-500 !important;
        }
    }
    &error-{
        &100{
            color: $error-100 !important;
        }
        &200{
            color: $error-200 !important;
        }
        &300{
            color: $error-300 !important;
        }
        &400{
            color: $error-400 !important;
        }
        &500{
            color: $error-500 !important;
        }
    }
    &warning-{
        &100{
            color: $warning-100 !important;
        }
        &200{
            color: $warning-200 !important;
        }
        &300{
            color: $warning-300 !important;
        }
        &400{
            color: $warning-400 !important;
        }
        &500{
            color: $warning-500 !important;
        }
    }
    &success-{
        &100{
            color: $success-100 !important;
        }
        &200{
            color: $success-200 !important;
        }
        &300{
            color: $success-300 !important;
        }
        &400{
            color: $success-400 !important;
        }
        &500{
            color: $success-500 !important;
        }
    }
    &decoration-{
        &none{
            text-decoration: unset !important;
        }
        &underline{
            text-decoration: underline !important;
        }
        &line-through{
            text-decoration: line-through !important;
        }
    }
    &hh-{
        &yellow{
            color: $hh-yellow !important;
        }
    }
    &ld-{
        &blue{
            color: $ld-blue !important;
        }
    }
}

.font-{
    &weight{
        &400{
            font-weight: 400;
        }
        &700{
            font-weight: 700;
        }
    }
    &size-{
        &12{
            font-size: 12px !important;
        }
        &13{
            font-size: 13px !important;
        }
        &14{
            font-size: 14px !important;
        }
        &20{
            font-size: 20px !important;
        }
        &24{
            font-size: 24px !important;
        }
    }
    &variant-{
        &body1{
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 24px !important;
        }
        &v2{
            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: 16px !important;
        }
        &v4{
            font-size: 16px !important;
            font-weight: 600 !important;
            line-height: 18px !important;
        }
        &v6{
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 22px !important;
        }
        &v7{
            font-size: 14px !important;
            font-weight: 600 !important;
            line-height: 17px !important;
        }
        &v8{
            font-size: 14px !important;
            font-weight: 700 !important;
            line-height: 17px !important;
        }
        &v9{
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 17px !important;
        }
        &v10{
            font-size: 16px !important;
            font-weight: 600 !important;
            line-height: 24px !important;
        }
        &v11{
            font-size: 20px !important;
            font-weight: 400 !important;
            line-height: 40px !important;
        }
        &v12{
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 16px !important;
        }
    }
}

.variant-{
    &subheading-{
        &3{
            font-weight: 500;
            font-size: 12px !important;
            line-height: 16px !important;
            @media (max-width:600px){
                fontSize: 10px !important;
            }
        }
        &4{
            font-weight: 500;
            font-size: 10px !important;
            line-height: 16px !important;
        }
    }
    &xsmall{
        font-weight: 400;
        font-size: 12px !important;
        line-height: 16px !important;
    }
    &caption-{
        &sm{
            font-size: 10px !important;
        }
    }
    &overline-{
        &sm{
            font-size: 10px !important;
        }
        &xs{
            font-size: 8px !important;
        }
    }
}

// Logos
.logo-{
    &default{
        height: 18px;
        @media ($breakpoint-down-sm){
            height: 16px;
        }
    }
    &unid{
        width: 65.22px;
        height: 20px;
        @media ($breakpoint-down-sm){
            width: 52.17px;
            height: 16px;
        }
    }
    &uerre{
        width: 94.87px;
        height: 18px;
        @media ($breakpoint-down-sm){
            width: 84.32px;
            height: 16px;
        }
    }
    &coursera{
        @media ($breakpoint-down-sm){
            width: 98px;
            height: 14px;
        }
    }
    &inlearning{
        @media ($breakpoint-down-sm){
            width: 88.16px;
            height: 19px;
        }
    }
    &google{
        @media ($breakpoint-down-sm){
            width: 129.11px;
            height: 20.22px;
        }
    }
}

.code-block-component{
    border: 2px solid $fill-stroke-400 !important;
    padding: 32px 16px;
}

.code-tag{
    background-color: #21272d;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 14px;
}

.disable-hover{
    &:hover{
        background-color: transparent !important;
    }
}

.card-skeleton-light {
    background: linear-gradient(110deg, #e5e5e5 8%, #d5d5d5 18%, #e5e5e5 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    border-radius: 4px;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}


