@media (max-width: 480px) {
    div.FeedCourses_GoToCourses {
        flex-grow: 0;
        width: 100%;
    }

    hr.FeedCourses_Separator {
        display: none;
    }

    button.FeedAddNew {
        width: '289px';
        height: '48px';
        text-transform: 'none';
        margin-top: '20px';
    
    }

    div.FeedNotFound {
        margin-top: 5em;
    }
}

@media (max-width: 693px) {
    button.FeedAddNew {
        float: left !important;
        margin-top: 0px !important;
        margin-bottom: 1.5em;
        width: 100% !important;
    }

    div.FeedNotFound {
        margin-top: 6em;
    }
}

@media (max-width: 600px) {
    div.FeedCourses {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        width: 100% !important;
        height: 600px;
    }
}

@media (max-width: 700px) {
    div.FeedCourses {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        width: 100% !important;
    }
}

@media (max-width: 900px) {
    div.FeedCourses {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media (max-width: 1020px) {
    div.FeedCourses {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

body>embed {
    overflow-y: hidden;
}

@media (max-width: 500px) {
    div.Feed_Root {
        padding-bottom: 30px;
    }
}

.Feed_Root {
    width: 100%;
    height: 100%;
}

.Feed_Header {
    align-items: center;
    display: inline-flex;
    font-size: 32px !important;
    line-height: 24px !important;
    margin-bottom: 20px !important;
    width: 50%;
}

.Feed_UserName {
    margin-bottom: 16px;
}

.BoxPublicar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.FeedCourses {
    width: auto;
    background-color: #1C1F24 !important;
    border-radius: 8px !important;
    margin: 12px 0px;
}

.FeedCourses_Header {
    padding: 24px;
    width: 100%;
    height: 80px;
    color: #F5F5F5;
}

.FeedCourses_GoToCourses {
    float: right;
    color: #FFFFFF;
    cursor: pointer;
    border: 1px solid #A86FFB;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    width: 200px;
    height: 36px;
}

.FeedCourses_GoToCourses:hover {
    border: 1px solid #9F6FFB;
    background-color: #9f6ffb14;
}

.FeedCourses_GoToCoursesLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 40px !important;
}

.FeedCourse_CardContainer {
    width: auto;
    height: 158px;
    border-radius: 8px 0px 0px 8px;
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
}

.FeedCourses_Card {
    display: flex;
    width: auto;
    height: 158px;
    border: 1px solid #404150;
}

.FeedShortcuts_Card {
    display: block;
    width: 308px;
    height: 313px;
    margin-top: 12px;
    background-color: #1C1F24 !important;
    border-radius: 8px !important;
}

.FeedShortcuts_Box {
    display: flex;
    width: 290px;
    height: 40px;
    margin: 12px;
    justify-content: space-evenly;
    align-items: center;
}

.FeedShortcuts_Border {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    background: #1C1F24;
    border: 0.5px solid #9F6FFB;
    border-radius: 40px;
    padding-top: 2px;
    text-align: center;
}

.FeedShortcuts_Divider {
    width: "5%";
    margin-left: "auto";
    margin-right: "auto";
    margin-top: "62px";
    margin-bottom: "62px";
    background-color: #2C3236;
}

.FeedCourses_Separator {
    border-top: 1px solid #404150;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin-top: -10px;
}

.FeedCourses_CardMedia {
    width: 50%;
    height: 158px;
}

.FeedCourses_CardDetail {
    background-color: #1C1F24;
    width: 50%;
    height: 158px;
    padding-left: 24px !important;
}

.FeedCourses_ModalityIcon {
    margin-left: -18em;
    margin-top: 12em;
    background: rgba(28, 31, 36, 0.8) !important;
    text-align: center;
}

.FeedCourses_CardDetailLabel {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    text-align: left;
    height: 33px;
    overflow: hidden;
}

.FeedCourses_CardDetailSubLabel {
    font-weight: 600 !important;
    font-size: 11px !important;
    line-height: 16px !important;
    color: #00C1DE !important;
    margin-top: 7px !important;
    text-align: left;
}

.FeedCourses_CardDetailLinearProgress {
    width: 85%;
    height: 4px;
    background: #9F6FFB;
}

.FeedCourses_CardDetailLinearProgressLabel {
    width: 85%;
    font-weight: 600 !important;
    font-size: 11px !important;
    line-height: 16px !important;
    text-align: right;
    color: #00C1DE !important;
}

.FeedCourses_CardDetailContinueLink {
    text-decoration: none;
}

.FeedCourses_CardDetailContinueBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 16px;
    gap: 4px;
    width: 85%;
    height: 28px;
    border: 1px solid #A86FFB;
    border-radius: 40px;
    margin-top: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 24px !important;
    cursor: pointer;
}

.FeedCourses_CardDetailContinueBtn:hover {
    border: 1px solid #9F6FFB;
    background-color: #9f6ffb14;
}

.FeedCourse_Loading {
    margin: 22.5% 46%;
}

.FeedCourses_CardNotAvailableLabel {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #00C1DE !important;
    margin-top: 20px !important;
    text-align: left;
}

.FeedNavigationBarTop {
    float: right;
    margin-top: 1em;
    margin-bottom: 1em;
}

.FeedNavigationBarBottom {
    float: right;
    margin-top: 1em;
    padding-bottom: 5em;
}

.FeedGridTitle {
    color: inherit;
    cursor: pointer;
    display: flex;
    margin-right: 0.5em;
    margin-top: 2px;
    text-decoration: none;
}

.FeedGridTitle:hover {
    color: gray;
    text-decoration: none;
}

.FeedStatusActive {
    color: limegreen,
}

.FeedStatusStandby {
    color: orange;
}

.FeedImage {
    background: #f0f4fa;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.FeedVideoFrame {
    animation-play-state: paused;
    border: 0;
    height: 100%;
    width: 100%;
}

.FeedGridAuthor {
    color: rgb(0, 193, 222);
}

.FeedLikeIconsContainer {
    height: 14px;
    width: 100%;
}

.FeedLikeIcons {
    color: #A0A9BA;
    cursor: pointer;
    height: 13.23px !important;
}

.FeedLikeIcons-2 {
    color: #A0A9BA;
    cursor: pointer;
}

.FeedBlogContainer {
    border: 2px solid #F0F4FA;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
}

.FeedAddComment {
    background-color: transparent;
    border-radius: 16px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    outline: none;
    padding-left: 7px;
    padding-right: 7px;
    resize: none;
    width: -webkit-fill-available;
}

.FeedAddComment::placeholder {
    color: lightgray;
    font-family: 'Source Sans Pro', sans-serif;
}

.FeedCommentContainer {
    background-color: #F6F6F6;
    margin-bottom: -1em;
}

.FeedUserCommentContainer {
    max-height: 200px !important;
    overflow-y: auto;
}

.FeedUserCommentContainer::-webkit-scrollbar {
    width: 10px;
}

.FeedUserCommentContainer::-webkit-scrollbar-track {
    background: #F1F1F1;
    border-radius: 10px;
}

.FeedUserCommentContainer::-webkit-scrollbar-thumb {
    background: #9999;
    border-radius: 10px;
}

.FeedAddCommentButton {
    color: lightgray;
    cursor: pointer;
    margin-left: 3px;
}

.FeedAddCommentButton:hover {
    color: gray;
}

.FeedUserComment {
    background-color: #eee;
    border-radius: 10px;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
    width: 95%;
}

.FeedUserCommentButton {
    height: 20px;
    width: 20px;
}

.FeedAdminSearchInput {
    font-family: 'Source Sans Pro', sans-serif !important;
    float: left;
    margin-bottom: 0.5em;
    width: 350px;
}

.MuiTableContainer-root,
.MuiTableCell-root {
    font-family: 'Source Sans Pro', sans-serif;
}

.MuiTypography-root {
    font-family: 'Source Sans Pro', sans-serif;
}