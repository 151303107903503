
.chip{
    &1{
        &-fs{
            &-200{
                padding: 4px 8px !important;
                background: $fill-stroke-200 !important;
                border-radius: 2px !important;
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 16px !important;
                color: $gray-100 !important;
                height: 26px !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
            &-300{
                padding: 4px 8px !important;
                background: $fill-stroke-300 !important;
                border-radius: 4px !important;
                font-weight: 600 !important;
                font-size: 16px !important;
                line-height: 24px !important;
                color: $gray-100 !important;
                height: 32px !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
        }
        &-white{
            padding: 4px 8px !important;
            background: $white !important;
            border-radius: 2px !important;
            font-weight: 600 !important;
            font-size: 14px !important;
            line-height: 16px !important;
            color: $unid-blue !important;
            height: 24px !important;
            & .MuiChip-label{
                padding: 0 !important;
            }
        }
        &-hh{
            &-black{
                padding: 4px 8px !important;
                background: $hh-black !important;
                border-radius: 2px !important;
                height: 26px !important;
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 16px !important;
                color: $gray-100 !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
        }
    }
    &2{
        &-bought{
            display: inline-flex !important;
            padding: 4px 8px !important;
            justify-content: center !important;
            align-items: center !important;
            gap: 8px !important; 
            border-radius: 2px !important;
            background: linear-gradient(342deg, #CDACFC 6.74%, #8B3FFA 89.58%) !important;
            font-family: Inter !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: 16px !important;
            width: fit-content !important;
            height: 24px !important;
            & .MuiChip-label{
                padding: 0 !important;
            }
        }
    }
    &3{
        &-unid{
            &-magenta{
                padding: 3px 8px !important;
                background: $unid-magenta !important;
                border-radius: 2px !important;
                height: 22px !important;
                font-weight: 600 !important;
                font-size: 11px !important;
                line-height: 16px !important;
                color: $white !important;
                letter-spacing: 2px !important;
                text-transform: uppercase !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
        }
        &-blue{
            &-400{
                display: block !important;
                width: fit-content !important;
                padding: 3px 8px !important;
                background: $blue-400 !important;
                border-radius: 2px !important;
                height: 22px !important;
                font-weight: 600 !important;
                font-size: 11px !important;
                line-height: 16px !important;
                color: $white !important;
                letter-spacing: 2px !important;
                text-transform: uppercase !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
            &-300{
                &-inverted{
                    padding: 3px 0px !important;
                    background: transparent !important;
                    border-radius: 2px !important;
                    height: 22px !important;
                    font-weight: 600 !important;
                    font-size: 12px !important;
                    line-height: 16px !important;
                    letter-spacing: 2px !important;
                    text-transform: uppercase !important;
                    & .MuiChip-label{
                        color: $blue-300 !important;
                        padding: 0 !important;
                    }
                }
            }
            &-500{
                &-inverted{
                    padding: 3px 0px !important;
                    background: transparent !important;
                    border-radius: 2px !important;
                    height: 22px !important;
                    font-weight: 600 !important;
                    font-size: 12px !important;
                    line-height: 16px !important;
                    letter-spacing: 2px !important;
                    text-transform: uppercase !important;
                    & .MuiChip-label{
                        color: $blue-500 !important;
                        padding: 0 !important;
                    }
                }
            }
        }
        &-green{
            &-500{
                padding: 3px 8px !important;
                background: $success-500 !important;
                border-radius: 2px !important;
                height: 22px !important;
                font-weight: 600 !important;
                font-size: 11px !important;
                line-height: 16px !important;
                color: $white !important;
                letter-spacing: 2px !important;
                text-transform: uppercase !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
        }
        &-orange{
            &-400{
                padding: 3px 8px !important;
                background: $orange-400 !important;
                border-radius: 2px !important;
                height: 22px !important;
                font-weight: 600 !important;
                font-size: 11px !important;
                line-height: 16px !important;
                color: $white !important;
                letter-spacing: 2px !important;
                text-transform: uppercase !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
                @media (max-width:390px){
                    padding: 0px 8px !important;
                    height: 16px !important;
                    font-weight: 600 !important;
                    font-size: 10px !important;
                    letter-spacing: 2px !important;
                    text-transform: uppercase !important;
                }
            }
        }
        &-ld{
            &-blue{
                padding: 3px 8px !important;
                background: $ld-blue !important;
                border-radius: 2px !important;
                height: 22px !important;
                font-weight: 600 !important;
                font-size: 11px !important;
                line-height: 16px !important;
                color: $white !important;
                letter-spacing: 2px !important;
                text-transform: uppercase !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
                @media (max-width:390px){
                    padding: 0px 8px !important;
                    height: 16px !important;
                    font-weight: 600 !important;
                    font-size: 10px !important;
                    letter-spacing: 2px !important;
                    text-transform: uppercase !important;
                }
            }
        }
        &-magenta{
            &-400{
                padding: 3px 8px !important;
                background: $magenta-400 !important;
                border-radius: 2px !important;
                height: 22px !important;
                font-weight: 600 !important;
                font-size: 11px !important;
                line-height: 16px !important;
                color: $white !important;
                letter-spacing: 2px !important;
                text-transform: uppercase !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
                @media (max-width:390px){
                    padding: 0px 8px !important;
                    height: 16px !important;
                    font-weight: 600 !important;
                    font-size: 10px !important;
                    letter-spacing: 2px !important;
                    text-transform: uppercase !important;
                }
            }
        }
        &-magenta{
            display: block !important;
            width: fit-content !important;
            padding: 3px 8px !important;
            background: $unid-magenta !important;
            border-radius: 2px !important;
            height: 22px !important;
            font-weight: 600 !important;
            font-size: 11px !important;
            line-height: 16px !important;
            color: $white !important;
            letter-spacing: 2px !important;
            text-transform: uppercase !important;
            & .MuiChip-label{
                padding: 0 !important;
            }
        }
        &-orange{
            &-400{
                display: block !important;
                width: fit-content !important;
                padding: 3px 8px !important;
                background: $orange-400 !important;
                border-radius: 2px !important;
                height: 22px !important;
                font-weight: 600 !important;
                font-size: 11px !important;
                line-height: 16px !important;
                color: $white !important;
                letter-spacing: 2px !important;
                text-transform: uppercase !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
        }
    }
    &7{
        &-fs{
            &-400{
                padding: 4px 8px !important;
                background: rgba(28, 31, 36, 0.8) !important;
                border-radius: 4px !important;
                height: 24px !important;
                font-weight: 400 !important;
                font-size: 10px !important;
                line-height: 16px !important;
                color: $gray-200 !important;
                width: fit-content !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
                & .MuiChip-icon{
                    font-size: 12px !important;
                    margin: 0 4px 0 0 !important;
                }
                @media (max-width:390px){
                    height: 18px !important;
                }
            }
        }
    }
    &10{
        &-approved{
            display: inline-flex !important;
            padding: 8px 16px !important;
            align-items: center !important;
            gap: 4px !important; 
            border-radius: 83px !important;
            border: 1px solid $success-300 !important;
            background: $success-500 !important;
            width: fit-content !important;
            font-family: Inter !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: 24px !important;
            height: 40px !important;
            & .MuiChip-icon{
                font-size: 24px !important;
                background: $success-500 !important;
                margin: 0 4px 0 0 !important;
            }
            & .MuiChip-label{
                padding: 0 !important;
            }
        }
    }
    &-outlined-{
        &blue{
            border: 0.5px solid $blue-300 !important;
            background: rgba(16, 88, 112, 0.30) !important;
        }
        &purple{
            border: 0.5px solid $violet-300 !important;
            background: $violet-500 !important;
        }
    }
    &-optative{
        padding: 4px 8px !important;
        background: rgba(222, 44, 251, 0.80) !important;
        border-radius: 4px !important;
        height: 24px !important;
        font-weight: 400 !important;
        font-size: 10px !important;
        line-height: 16px !important;
        color: $gray-200 !important;
        width: fit-content !important;
        & .MuiChip-label{
            padding: 0 !important;
        }
        & .MuiChip-icon{
            font-size: 12px !important;
            margin: 0 4px 0 0 !important;
            background-color: transparent !important;
        }
        @media (max-width:390px){
            height: 18px !important;
        }
    }
}

@media (max-width:600px) {
    .chip{
        &1{
            &-fs{
                &-200{
                    padding: 1px 8px !important;
                    background: $fill-stroke-200 !important;
                    border-radius: 2px !important;
                    font-weight: 400 !important;
                    font-size: 12px !important;
                    line-height: 16px !important;
                    color: $gray-100 !important;
                    height: 18px !important;
                    & .MuiChip-label{
                        padding: 0 !important;
                    }
                }
                &-300{
                    padding: 4px 6px !important;
                    background: $fill-stroke-300 !important;
                    border-radius: 2px !important;
                    font-weight: 600 !important;
                    font-size: 12px !important;
                    line-height: 16px !important;
                    color: $gray-100 !important;
                    height: 16px !important;
                    & .MuiChip-label{
                        padding: 0 !important;
                    }
                }
            }
            &-white{
                padding: 1px 8px !important;
                font-size: 12px !important;
                height: 18px !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
            &-hh{
                &-black{
                    padding: 1px 8px !important;
                    height: 18px !important;
                    font-size: 12px !important;
                }
            }
        }
        &2{
            &-bought{
                display: inline-flex !important;
                padding: 4px 8px !important;
                justify-content: center !important;
                align-items: center !important;
                gap: 8px !important; 
                border-radius: 2px !important;
                background: linear-gradient(342deg, #CDACFC 6.74%, #8B3FFA 89.58%) !important;
                font-family: Inter !important;
                font-size: 10px !important;
                font-style: normal !important;
                font-weight: 600 !important;
                line-height: 16px !important;
                width: fit-content !important;
                height: 18px !important;
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
        }
        &3{
            &-green{
                &-500{
                    padding: 0px 8px !important;
                    height: 16px !important;
                    font-weight: 600 !important;
                    font-size: 10px !important;
                }
            }
            &-blue{
                &-400{
                    padding: 0px 8px !important;
                    height: 16px !important;
                    font-weight: 600 !important;
                    font-size: 10px !important;
                }
                &-300{
                    &-inverted{
                        padding: 0px !important;
                        background: transparent !important;
                        border-radius: 2px !important;
                        height: 22px !important;
                        font-weight: 600 !important;
                        font-size: 10px !important;
                        line-height: 16px !important;
                        letter-spacing: 2px !important;
                        text-transform: uppercase !important;
                    }
                }
            }
            &-magenta{
                padding: 0px 8px !important;
                height: 16px !important;
                font-weight: 600 !important;
                font-size: 10px !important;
            }
            &-orange{
                &-400{
                    padding: 0px 8px !important;
                    height: 16px !important;
                    font-weight: 600 !important;
                    font-size: 10px !important;
                }
            }
            &-transparent{
                padding: 0px 8px !important;
                height: 16px !important;
                font-weight: 600 !important;
                font-size: 10px !important;
            }
        }
        &7{
            &-fs{
                &-400{
                    padding: 4px 8px !important;
                    background: rgba(28, 31, 36, 0.8) !important;
                    border-radius: 4px !important;
                    height: 24px !important;
                    font-weight: 400 !important;
                    font-size: 10px !important;
                    line-height: 16px !important;
                    color: $gray-200 !important;
                    & .MuiChip-label{
                        padding: 0 !important;
                    }
                    @media (max-width:390px){
                        height: 18px !important;
                    }
                }
            }
        }
        &10{
            &-approved{
                display: inline-flex !important;
                padding: 8px 16px !important;
                align-items: center !important;
                gap: 4px !important; 
                border-radius: 83px !important;
                border: 1px solid $success-300 !important;
                background: $success-500 !important;
                width: fit-content !important;
                font-family: Inter !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 600 !important;
                line-height: 24px !important;
                height: 32px !important;
                & .MuiChip-icon{
                    font-size: 16px !important;
                    background: $success-500 !important;
                    margin: 0 4px 0 0 !important;
                }
                & .MuiChip-label{
                    padding: 0 !important;
                }
            }
        }
    }
}