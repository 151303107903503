
  
#miDiv2 {
    height: 70px;
    width: 70px;
    background-color: #F83B5A66;
    border-radius: 50%;
    // display: none;
    // opacity: 0;
}
  
#miDiv {
    height: 59px;
    width: 59px;
    background-color: #F83B5A;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
    // display: none;
    // opacity: 0;
}
  
@keyframes fadeIn {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}

@keyframes fadeOut {
from {
    opacity: 1;
}
to {
    opacity: 0;
}
}

.fadeIn {
animation: fadeIn 0.2s forwards;
}

.fadeOut {
animation: fadeOut 0.2s forwards;
}
  