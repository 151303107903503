.table-headers {
    background-color: #1C333C !important;
    /* border-right: 0.3px solid #219EBC; */
}

.table-cells {
    background-color: #111111!important;
    /* border: 1px solid #219EBC; */
}

.table-title {
    background-color: #1C1F24!important;
}
