.options-wrapper {
    display: flex;
}

@media (max-width: 600px) {
    .detail-view-wrapper {
        margin: 0 !important;
        padding: 0 !important;
    }
  }

.detail-view-wrapper {
    border-radius: 8px;
    height: 1065px;
    margin: 0 1em;
    padding: 0 1em;
    width: 1096px;
}
