input,
button,
textarea {
    font-family: 'Source Sans Pro', sans-serif !important;
}

.Profile_Pic {
    width: 100%;
}

.Profile_Title {
    display: flex;
}

.Profile_FormControl {
    display: flex;
}

.Profile_ChevronPointer {
    cursor: pointer;
}

.Profile_ChevronLeft {
    cursor: pointer;
}

.Profile_ChevronRight {
    cursor: pointer;
    float: right;
}

.Profile_Separator {
    border: 0px;
    border-top: 0.5px solid #DCDFE5;
}

.Profile_DetailContent {
    font-size: 10px;
    font-weight: 600;
}

.Profile_VisibilityFields {
    border-radius: 5px;
    text-transform: none;
    width: 200px;
}

.Profile_noteContainer {
    color: #B2B4B6;
    display: flex;
    font-size: 12px;
    width: 80%;
}

.Profile_note {
    text-transform: none;
    width: 200px;
}

.Profile_AddedButton {
    font-size: 14px;
    text-transform: none;
}

.Profile_AddedButton:hover {
    background-color: #1C1F24;
}

.Profile_buttonsContainer {
    margin-left: 1em;
    float: right;
}

.Profile_Button {
    border: 0px;
    font-size: 12px !important;
    font-weight: 600;
    height: 35px;
    margin-left: 10px;
    text-transform: none;
    width: 90px;
}

.Profile_AlertMessage {
    float: left;
    margin-top: 14px;
}
