.bttn-unid-yellow{
    border: 1px solid $unid-yellow !important;
    border-radius: 40px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: $gray-100 !important;
    padding: 8px 24px !important;
}

.bttn-white{
    border: 1px solid $white !important;
    border-radius: 40px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: $gray-100 !important;
    padding: 8px 24px !important;
}

.bttn-hh-yellow{
    border: 1px solid $hh-yellow !important;
    border-radius: 40px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: $gray-100 !important;
    padding: 8px 24px !important;
}