.MuiTableContainer-root, .MuiTableCell-root {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 9pt;
    vertical-align: top;
}

.MuiTypography-root {
    font-family: 'Source Sans Pro', sans-serif;
}

.AdminNotificationsViewAll {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 9pt;
}

.AdminNotificationsSearchInput {
    font-family: 'Source Sans Pro', sans-serif !important;
    float: left;
    margin-bottom: 0.5em;
    width: 350px;
}

.AdminNotificationsTitle {
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.AdminNotificationsContent {
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.AdminNotificationsURL {
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.AdminNotificationsIcon {
    width: 15px;
}

.NotificationsNavigationBarTop {
    float: right;
    margin-top: 1em;
    margin-bottom: 0.6em;
}

.NotificationsNavigationBarBottom {
    float: right;
    margin-top: 1em;
    padding-bottom: 5em;
}

.NotificationsGridTitle {
    color: inherit;
    cursor: pointer;
    display: flex;
    margin-right: 0.5em;
    margin-top: 2px;
    text-decoration: none;
}

.NotificationsGridTitle:hover {
    color:gray;
    text-decoration: none;
}

.NotificationsGridRight {
    text-align: right;
}

.NotificationsGridLeft {
    text-align: left;
}

.NotificationsIsCriticalGrid {
    text-align: right;
    display: table;
}

.NotificationsCardContent {
    background-color: #333333;
    padding: 0;
}

span.NotificationsCardContent {
    color: white;
    font-size: 9pt;
}

.note {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 9pt;
    color: #B2B2B2
}

.note:hover {
    color: #333333
}