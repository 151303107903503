.photo-container {
    position: relative;
    cursor: pointer;
}
  
.photo {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}
  
.edit-icon {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
  
.photo-container:hover .photo {
    opacity: 0.3;
}
  
.photo-container:hover .edit-icon {
    opacity: 1;
}
