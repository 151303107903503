// Variables

// Clases de utilidad de visualización
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

// Clases de visualización responsiva
.d-sm-none {
  @media (max-width: $breakpoint-sm) {
    display: none !important;
  }
}

.d-md-none {
  @media (max-width: $breakpoint-md) {
    display: none !important;
  }
}

.d-lg-block {
  @media (min-width: $breakpoint-md) {
    display: block !important;
  }
}

@media (max-width: $breakpoint-md) {
  .flex-md-column{
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .flex-sm-column{
    flex-direction: column;
  }
}
@media (max-width:390px) {
  .flex-xs-column{
    flex-direction: column;
  }
}

