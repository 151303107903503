.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print {
  main {
    margin: 0 !important;
    padding: 0 !important;
  }

  header nav, footer {
    display: none;
  }

  body * {
    overflow: visible !important;
    visibility: hidden !important;
    height: 0;
  }
  
  .printable, .printable * {
    visibility: visible !important;
    height: auto;
  }

  .printable {
    position: absolute;
    left: 0;
    top: 0;
  }

  .no-print, .no-print * {
    display: none !important;
  }
}

.card-skeleton{
  background: linear-gradient(110deg, #78787D 8%, #888889 18%, #78787D 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  
}
.card-skeleton-v2{
  background: linear-gradient(110deg, #4d4d52 8%, #575758 18%, #4d4d52 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  border-radius: 4px;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow:  0 0 0 100px #1C1F24 inset !important;
  -webkit-box-shadow: 0 0 0 100px #1C1F24 inset !important;
  -webkit-text-fill-color: #FFFF !important;
  caret-color: #ffff !important;
  font-weight: 400px;
  padding: 14px;
  background-clip: content-box !important;
}
