@import "./design-system/assets/styles/variables/colors.scss";

#hubspotform{
   .input > input {
    color: $gray-100;
    margin: 12px 16px 16px 0px;
    //background-color: #404150;
    //background: #78787D;
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 10px;
    
    width: 100%;
    height: 40px;
    
    /* Fills and strokes/200 */
    
    border: 1px solid $fill-stroke-200;
    border-radius: 8px;

    font: inherit;
    color: currentColor;
    padding: 11px 16px !important;
    min-width: 0;
    background: none;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
   }

   .input > input:focus{
      border: 1px solid $violet-300;
      outline: none;
   }

   label {
    font-weight: 600;
    size: 14px;
    color: $gray-100;
   }

   span {
    font-weight: 400;
   }

   .actions{
      margin-top: 27px;
   }
   .actions > input{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //padding: 16px 24px;
    gap: 8px;

    width: 488px;

    @media (max-width: 600px) {
      width: 100%;
    }
    height: 48px;

    /* Electric blue/300 (Main) */

    background: $electric-blue-300;
    color: white;
    font-size: 14px;
    box-shadow: unset;
    font-weight: 600;
    line-height: 16px;
    font-family: 'Source Sans Pro', sans-serif !important;
    border-radius: 8px;
    border: none;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
   }

   .actions > input:hover{
    background-color: $violet-300;
    cursor: pointer;
   }

   ul {
      margin-top: 0px;
      margin-left: 0px;
      padding-left: 0px;
      margin-bottom: 16px;
   }
   ul > li {
      font-size: 12px;
      list-style-type: none;
      
   }
   ul > li > .hs-error-msg {
      color: $error-300;
   }

   ul > li > label > input{
      margin-left: '36px';
   }

   ul > li > label > span > a{
      color: $orange-300;
   }

   p > a{
      color: $orange-300;
   }
}
 