.horario_container {
    display: flex;
    flex-wrap: wrap;
}

.horario-periodo {
    align-content: center;
    align-items: center;
    background: #1C1F24 !important;
    border: 0.5px solid rgba(255, 255, 255, 0.15) !important;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.3em;
    width: 48em;
}

.align-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 8px;
}

.align-elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.period-btn {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.2em;
    justify-content: space-around;
}

.period-container {
    background-color: #1C333C !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

@media (max-width: 1215px) {
    ::-webkit-scrollbar {
        height: 10px;
        width: 12px;
    }

    ::-webkit-scrollbar-corner {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #000;
        border-radius: 15px;
    }

    ::-webkit-scrollbar-track {
        background: #404150;
    }
}

@media (max-width: 960px) {
    .period-container {
        margin-top: 1em;
    }
}

@media (max-width: 496px) {
    .recharts-surface {
        width: 260px;
    }

    .period-label {
        font-size: 12px !important;
    }

    .variation {
        display: none;
    }
}

.weekdays-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.weekday-chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.5em;
    gap: 5px;
    border: 0.5px solid #219EBC !important;
    border-radius: 100px;
    width: 12.5%;
    margin: 0 0.40em !important;
}

.responsive-weekday-chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.5em;
    gap: 5px;
    border: 0.5px solid #219EBC !important;
    border-radius: 100px;
    width: 100%;
}

.hour-chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    border: none;
    background-color: #111111 !important;
    border-radius: 100px;
    width: 7.2em;
    margin: 0 0.5em;
}

.responsive-hour-chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    border: none;
    background-color: #111111 !important;
    border-radius: 100px;
    width: 7.2em;
    margin: 0 1.5em;
    padding-left: 25px;
}

.class-chip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    gap: 5px;
    background-color: #111111 !important;
    border-radius: 0.5em;
    height: 5em;
    width: 7.2em;
    text-align: center;
}

.responsive-class-chip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    gap: 5px;
    background-color: #111111 !important;
    border-radius: 0.5em;
    height: 5em;
    width: 7.2em;
    text-align: center;
    margin-right: 1em;
}

.enabled {
    background-color: #219EBC !important;
}

.class-chip-rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #111111 !important;
}

.week-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 6em;
    left: 17px;
    top: 345px;
    align-items: center;
    background-color: #111111 !important;
    width: 100%;
}

.responsive-week-row {
    display: flex;
    flex-direction: row;
    height: 6em;
    left: 17px;
    top: 345px;
    align-items: center;
    background-color: #111111 !important;
    justify-content: center;
    width: 100%;
}