.text-lowercase {
    text-transform: lowercase!important;
}
.text-uppercase {
    text-transform: uppercase!important;
}
.text-capitalize {
    text-transform: capitalize!important;
}
.text-transform-none {
    text-transform: none!important;
}
.text-left {
    text-align: left   !important;
}
.text-right {
    text-align: right  !important;
}
.text-center {
    text-align: center !important;
}

.subheading-{
    &2{
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
    }
}
@media (max-width:720px) {
    .body-text{
        &-samll-sm{
            font-size: 14px !important;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 18px !important;
        }
    }

    .h4-sm{
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 20px !important;
    }
}

@media (max-width:390px) {
    .h2-xs{
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 26px !important;
    }

    .text-start-xs{
        text-align: start !important;
    }

    .body-text{
        &-samll-xs{
            font-size: 14px !important;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 18px !important;
        }
        &-xsamll-xs{
            font-size: 12px !important;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 16px !important;
        }
    }

    .h5-xs{
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 16px !important;
    }

    .h4-xs{
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 20px !important;
    }
}