@import 'https://fonts.googleapis.com/css?family=Antic+Didone|Montserrat:700';


$white: #fff;

:root {
	margin: 0;
	padding: 0;
	font: {
		family: 'Antic Didone';
		size: 13px;
	}
}
@media screen and (min-width: 760px) {
	:root {
		font-size: 15px;
	}
}
@media screen and (min-width: 820px) {
	:root {
		font-size: 16px;
	}
}
@media screen and (min-width: 900px) {
	:root {
		font-size: 17px;
	}
}
@media screen and (min-width: 980px) {
	:root {
		font-size: 18px;
	}
}
@media screen and (min-width: 1060px) {
	:root {
		font-size: 19px;
	}
}
@media screen and (min-width: 1220px) {
	:root {
		font-size: 20px;
	}
}
@media screen and (min-width: 1400px) {
	:root {
		font-size: 22px;
	}
}

html,
body, 
#app {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

#app {
	position: relative;
	animation: fadeIn 500ms ease-in-out forwards;
}

.selectors {
	position: absolute;
	// top: auto;
	// left: 0;
	width: 30px;
	// background: rgba(0, 0, 0, 0.1);
	// height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
	top: 43px;
	.selector {
		border: 1px solid #404150;
		background: none;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		margin: 5px 0;
		opacity: 0.6;
		cursor: pointer;
		transition: background .3s ease-in-out, opacity .3s ease-in-out;
		&:hover,
		&.active {
			background: #3900D9;
			opacity: 0.9;
		}
	}
}

section.wrapper {
	width: 100%;
	height: 100%;
	background-size: cover;
	transition: background-image 500ms ease-in-out;
}

aside.panel {
	width: 40vw;
	height: 100%;
	opacity: 0.9;
	color: $white;
	box-shadow: 5px 0 25px 0 rgba(0, 0, 0, 0.3);
	transition: background 500ms ease-in-out;
	h2.panel-header,
	p.panel-info {
		margin: 0;
		padding: 1.3rem 3.5rem;
	}
	h2.panel-header {
		padding-top: 2em;
		font: {
			weight: normal;
			size: 2.5rem;
		}
		text-transform: capitalize;
	}
	p.panel-info {
		font: {
			size: 1.2rem;
		}
		line-height: 1.8em;
	}
	button.panel-button {
		margin: 1.3rem 3.5rem;
		padding: 0.8em 1.3em;
		background: none;
		border: 1px solid $white;
		color: $white;
		cursor: pointer;
		font: {
			family: 'Montserrat';
			size: 0.8rem;
			weight: 700;
		}
		text-transform: uppercase;
		transition: box-shadow 0.5s ease-in-out, color 0.5s ease-in-out;
		&:hover {
			box-shadow: inset 0 0 1.5em 1.5em $white;
		}
		&:focus {
			outline: none;
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}